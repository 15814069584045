<template>
  <div style="background: #00946b" class="flexpers">
    <v-container style="margin-top: auto; margin-bottom: auto">
      <div>
        <v-btn
          color="#fff"
          v-if="backId !== ''"
          class="ml-4"
          icon
          :to="{
            path: `/micrositio-membership/${backId}`,
            query: { step: 'members' },
          }"
        >
          <v-icon> mdi-arrow-left </v-icon> Volver
        </v-btn>
        <br />
        <v-card rounded="xl">
          <v-row class="my-auto" style="background: #fff">
            <v-col
              style="background: #f8fafc"
              :cols="$vuetify.breakpoint.smAndDown ? '12' : '4'"
              class="px-6 py-4 rounded-xl"
            >
              <v-row>
                <v-col cols="12" class="pb-0">
                  <v-btn
                    style="float: left"
                    :color="
                      homologateStatusWithColor(credInfo.validationStatus).color
                    "
                    width="auto"
                    :loading="load"
                    class="
                      font-weight-black
                      mx-auto
                      text-capitalize
                      font-weight-bold
                      rounded-pill
                    "
                  >
                    {{
                      homologateStatusWithColor(credInfo.validationStatus).status
                    }}
                  </v-btn>
                </v-col>
                <v-col cols="12" class="pt-0 pb-4 text-center">
                  <v-avatar color="#fbb406" size="100" class="mx-auto mt-4">
                    <span
                      v-if="credInfo.profilePicture == 'NOT_ASSIGNED'"
                      style="color: white; font-weight: bold; font-size: 20px"
                      >{{ profile }}</span
                    >
                    <v-img v-else :src="credInfo.profilePicture"></v-img>
                  </v-avatar>
                  <v-card-title
                    class="px-0 py-1 align-center justify-center"
                    style="
                      font-size: 1.2rem;
                      word-break: break-word;
                      font-weight: 600;
                    "
                    >{{ cred.name }} {{ cred.lastName }}</v-card-title
                  >
                  <!-- <div class="px-0 upercase py-1"> {{ credInfo.branch.company || "N/D" }}  {{ cred.company }}</div>
                  <div class="pb-3"> <span class="px-0 font-weight-black upercase "> MIEMBRO DESDE</span> - {{getbyFindIndex(asociado,'Miembro desde')}}</div> -->
                  <hr class="hr_color mt-5" />
                </v-col>
                <v-col cols="12">
                  <div class="d-flex py-1">
                    <v-icon color="black" class="pr-2"> mdi-domain </v-icon>
                    <span class="h5">{{
                      getbyFindIndex(asociado, "Compañia")
                    }}</span>
                  </div>
                  <div class="d-flex py-1">
                    <v-img
                      src="@/assets/images/landing/memberLanding/ico-telefono.svg"
                      max-width="25"
                      contain
                      class="mr-2"
                    />
                    <span class="h5">{{
                      getbyFindIndex(personalInfo, "Numero de Celular")
                    }}</span>
                  </div>
                  <div class="d-flex py-1">
                    <v-img
                      src="@/assets/images/landing/memberLanding/ico-email.svg"
                      max-width="25"
                      contain
                      class="mr-2"
                    />
                    <span class="h5">{{
                      getbyFindIndex(personalInfo, "Correo Electrónico")
                    }}</span>
                  </div>
                  <div class="d-flex py-1">
                    <v-img
                      src="@/assets/images/landing/memberLanding/ico-dir.svg"
                      max-width="25"
                      contain
                      class="mr-2"
                    />
                    <span class="h5"
                      >{{ getbyFindIndex(directions, "Municipio/Ciudad") }} -
                      {{ getbyFindIndex(directions, "Estado") }}</span
                    >
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              style="background: #fff"
              :cols="$vuetify.breakpoint.smAndDown ? '12' : '8'"
              class="px-6 py-4 rounded-xl"
            >
              <v-row class="pr-5">
                <v-col :cols="12" width="100%" elevation="0">
                  <v-card-title class="px-0 font-weight-black"></v-card-title>
                  <v-row :cols="12" width="100%" elevation="0">
                    <v-col
                      :cols="$vuetify.breakpoint.smAndDown ? '12' : '3'"
                      v-for="(item, i) in asociado"
                      :key="i"
                      class="pt-3 pb-2"
                      v-show="
                        (item.title == 'Miembro desde' ||
                          item.title == 'Teléfono fijo' ||
                          item.title == 'Rfc') &&
                        item.content != ''
                      "
                    >
                      <p>
                        <span style="font-weight: 800">{{ item.title }}: </span>
                        <br />
                        <small>{{ item.content }}</small>
                      </p>
                    </v-col>
                  </v-row>
                  <hr class="hr_color" />
                </v-col>

                <v-col>
                  <v-card-title class="px-0 font-weight-black"
                    >Dirección</v-card-title
                  >
                  <v-row :cols="12" width="100%" elevation="0">
                    <v-col
                      :cols="$vuetify.breakpoint.smAndDown ? '12' : '3'"
                      v-for="(item, i) in directions"
                      :key="i"
                      class="py-0"
                      v-show="item.content !== ''"
                    >
                      <p>
                        <span style="font-weight: bold"
                          >{{ item.title }}:
                        </span>
                        <br />
                        <small>{{ item.content }}</small>
                      </p>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" class="text-end">
                  <span class="px-0 font-weight-black upercase pr-6"
                    >MIEMBRO DE</span
                  >
                  <v-avatar size="100" class="hr_color mx-auto">
                    <span
                      v-if="
                        credInfo.headOffice.profilePicture == 'NOT_ASSIGNED'
                      "
                      style="color: white; font-weight: bold; font-size: 20px"
                      >{{ profile }}</span
                    >
                    <v-img
                      v-else
                      :src="credInfo.headOffice.profilePicture"
                    ></v-img>
                  </v-avatar>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { formatDate } from "@/utils/dates";
import { homologateStatusWithColor } from "@/common/hook/useHelper.js";

export default {
  data() {
    return {
      cred: {},
      credInfo: {},
      personalInfo: [],
      directions: [],
      asociado: [],
      profile: "",
      backId: "",
      load: false,
    };
  },
  computed: {
    ...mapState("user", ["user"]),
  },
  methods: {
    ...mapActions("microsite", ["getMemberInformation"]),
    ...mapMutations("loader", ["loading", "loaded"]),
    homologateStatusWithColor,
    getbyFindIndex(json, id) {
      let findIndex = json.findIndex((item) => item.title == id);
      return json[findIndex].content;
    },
  },
  async mounted() {
    this.loading();
    this.load = true;
    this.credInfo = (
      await this.getMemberInformation(this.$route.params.memberId)
    ).data;
    this.cred = this.credInfo.memberInformation;
    (this.personalInfo = [
      {
        title: "Numero de Teléfono",
        content: this.cred.numero_de_telefono || "",
      },
      {
        title: "Numero de Celular",
        content: this.cred.numero_de_celular || "",
      },
      //{ title: "Pagina Web", content: this.cred.pagina_web },
      { title: "Correo Electrónico", content: this.credInfo.email || "" },
    ]),
      (this.directions = [
        { title: "Calle", content: this.cred.calle || "" },
        { title: "Estado", content: this.cred.estado || "" },
        { title: "Colonia", content: this.cred.colonia || "" },
        { title: "Municipio/Ciudad", content: this.cred.municipio || "" },
        { title: "Cod. Postal", content: this.cred.codigo_postal || "" },
        { title: "Num. Exterior", content: this.cred.num_ext || "" },
        { title: "Num. Interior", content: this.cred.num_int || "" },
        { title: "Sitio web", content: this.cred.pagina_web || "" },
      ]),
      (this.asociado = [
        {
          title: "Miembro desde",
          content: this.cred.hasOwnProperty("memberFrom")
            ? formatDate(this.cred.memberFrom)
            : "N/D",
        },
        { title: "Teléfono fijo", content: this.cred.numero_de_telefono || "" },
        { title: "Nombre", content: this.cred.name || "" },
        { title: "Apellido", content: this.cred.lastName || "" },
        {
          title: "Compañia",
          content:
            this.cred.compania ||
            this.cred.company ||
            this.credInfo.company ||
            "",
        },
        { title: "Sección", content: this.credInfo.branch.company || "N/D" },
        { title: "Curp", content: this.cred.curp || "" },
        { title: "Clave AMPI", content: this.cred.clave_ampi || "N/D" },
      ]);
    this.loaded();
    this.load = false;
    this.profile =
      this.cred.name.split("")[0] + this.cred.lastName.split("")[0];
  },
  created() {
    if (this.$route.query.micrositeId) {
      this.backId = this.$route.query.micrositeId;
    }
  },
};
</script>


<style scoped>
.flexpers {
  display: flex;
  overflow-y: auto;
  height: 100vh;
}
.v-main__wrap {
  background: aliceblue !important;
}
.hr_color {
  border: 1px solid #c4c6c8;
}
.upercase {
  text-transform: uppercase !important;
}
</style>