<template>
  <div>
    <MemberCredential v-if="false"></MemberCredential>
    <MemberCredentialNew v-else></MemberCredentialNew>
  </div>
</template>

<script>
import MemberCredential from './memberCredential.vue'
import MemberCredentialNew from './memberCredentialNew.vue'
export default {
  components: {
    MemberCredential,
    MemberCredentialNew
  },
  computed: {
    isAmpi(){
      const micrositeId = this.$route.query.micrositeId
      //dev ampi: 62d707d0cdc3d0001b08cb84
      const ampi1 = '62d707d0cdc3d0001b08cb84'
      const ampi2 = '62d707d0cdc3d0001b08cb84'
      return micrositeId === ampi1 || micrositeId === ampi2
    }
  },
}
</script>

<style>

</style>